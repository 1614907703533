import { Link, graphql, StaticQuery, navigate  } from 'gatsby'
import React, { Component } from 'react'
import { TagCloud } from 'react-tagcloud';
import { includes, findIndex, filter, find, groupBy, compact, flatten, map } from 'lodash'

import './tags.css'

class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this.setData()
  }

  setData = () => {
    let tags = this.props.taggedPages.map(({ node: { frontmatter: { tags }}}) => {
      if (tags) {
        return tags.split(',').map(item => item.trim())
      }
    })
    tags = compact(flatten(tags))
    let dataBuffer = map(groupBy(tags),(array, tag) => { return { value: tag, count: array.length } })

    if (this.props.targetPage !== '') {
      const targetIndex = findIndex(this.props.taggedPages, o => o.node.id === this.props.targetPage)
      const currentPageTags = this.props.taggedPages[targetIndex].node.frontmatter.tags.split(',').map(item => item.trim())
      dataBuffer = dataBuffer.filter(item => includes(currentPageTags, item.value))
    }
    this.setState({ data: dataBuffer })
  }

  render() {
    const options = {
      luminosity: 'bright',
      hue: 'green'
    };
    return (
      <TagCloud
        className="tag-cloud"
        minSize={24}
        maxSize={72}
        colorOptions={options}
        tags={this.state.data}
        onClick={tag => navigate(`/${tag.value}`)}
      />
    )
  }
}

export default Tags
